'use strict';
var functions = require('./functions');

/*
 * Exported in 'functions.js' functions can be used here:
 * functions.exportName()
 */
window.addEventListener('scroll', function () {
    (window.scrollY > 10)
        ? document.querySelector('.header').classList.add('collapse')
        : document.querySelector('.header').classList.remove('collapse');
});

// Accordeon
document.querySelectorAll('.accordeon .accordeon-item .title').forEach(function (el) {
    el.addEventListener('click', function (e) {
        if (this.parentNode.classList.contains('active')) {
            this.parentNode.classList.remove('active');
        } else {
            document.querySelectorAll('.accordeon .accordeon-item').forEach(function (ael) {
                ael.classList.remove('active');
            });
            this.parentNode.classList.add('active');
        }
    });
})

// Mobile menu
document.querySelector('body').addEventListener('click', function (e) {
    if (!e.target.classList.contains('mobile-menu') && 
        document.querySelector('.mobile-menu').classList.contains('active') &&
        !e.target.classList.contains('menu-btn') &&
        !e.target.classList.contains('btn-icon')
    ) {
        document.querySelector('.mobile-menu').classList.remove('active');
    }
});
document.querySelectorAll('[mm-trigger]').forEach(function (element) {
    element.addEventListener('click', function (e) {
        document.querySelector('.mobile-menu').classList.toggle('active');
    });
});